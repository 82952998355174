<template>
  <v-container fluid>
    <v-row>
      <v-col class="titleWidth">
        <version-status-title
          title="風險類型與項目｜"
          :versionData="versionData"
        ></version-status-title>
      </v-col>
      <v-col v-if="!isFromSignOff()" class="d-flex align-baseline justify-end">
        <p class="grey--text mr-3 caption">更新時間：{{ updatedAt }}</p>
        <v-btn
          v-show="!editMode"
          @click="signHistory"
          text
          color="primary"
          class="mr-3 pa-0"
          >簽核軌跡</v-btn
        >
        <v-btn
          v-show="!editMode"
          to="/audit-field/risk-type"
          depressed
          class="mr-2"
          color="#fff"
          >返回列表</v-btn
        >
        <v-btn
          v-if="rolesCheck('riskTypeByIdApproval')"
          v-show="!editMode"
          @click="toSign"
          depressed
          class="mr-2"
          color="primary"
          >送簽</v-btn
        >
        <v-btn
          v-if="rolesCheck('riskTypeByIdEdit')"
          v-show="!editMode"
          @click="editMode = true"
          depressed
          color="primary"
          >編輯</v-btn
        >
        <v-btn v-show="editMode" @click="saveData()" depressed color="success"
          >儲存</v-btn
        >
      </v-col>
    </v-row>
    <div class="mb-3 d-flex justify-end align-end">
      <v-radio-group v-model="shrinkOrExpand" row hide-details>
        <v-radio
          value="allShrink"
          label="全部收起"
          class="ml-auto mr-3 expandRadio"
        ></v-radio>
        <v-radio
          value="allExpand"
          label="全部展開"
          class="expandRadio"
          :class="editMode ? '' : 'mr-0'"
        ></v-radio>
      </v-radio-group>

      <v-btn v-show="editMode" @click="editRisk(null)" color="primary"
        >新增風險</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="riskList"
      :items-per-page="5"
      :expanded.sync="expanded"
      class="elevation-1 material-table-expand"
      hide-default-footer
      show-expand
      item-key="id"
      expand-icon="mdi-chevron-down"
    >
      <template #[`header.actions`]>
        <span v-show="editMode">操作</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <div v-show="editMode" @click="toggleStatusAlert(item, 'main')">
          <v-icon
            :disabled="!item.enabled"
            @click="editRisk(item, 'main')"
            class="pointer mr-2"
            >mdi-pencil</v-icon
          >
          <!-- <v-icon @click="openSuspendConfrim(item, 'main')" class="pointer"
            >mdi-delete</v-icon
          > -->
        </div>
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip
          @click="openSuspendConfrim(item, 'main')"
          v-if="item.enabled"
          color="primary"
          small
          >啟用</v-chip
        >
        <v-chip @click="taggleStatus(item, true)" v-else small>停用</v-chip>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td v-if="item.items.length != 0">
          <div
            class="childRow"
            v-for="child in item.items"
            :key="child.id"
          ></div>
        </td>
        <td v-if="item.items.length != 0">
          <div
            class="childRow"
            v-for="child in item.items"
            :key="child.id"
          ></div>
        </td>
        <td v-if="item.items.length != 0">
          <div class="childRow" v-for="child in item.items" :key="child.id">
            {{ child.name }}
          </div>
        </td>
        <td v-if="item.items.length != 0">
          <div
            class="childRow px-4"
            v-for="child in item.items"
            :key="child.id"
            @click="toggleStatusAlert(child, 'child', item)"
          >
            <v-chip
              @click="openSuspendConfrim(child, 'child')"
              v-if="child.enabled"
              color="primary"
              small
              >啟用</v-chip
            >
            <v-chip
              :disabled="!item.enabled"
              @click="taggleStatus(child, true)"
              v-else
              small
              >停用</v-chip
            >
          </div>
        </td>
        <td v-if="item.items.length != 0">
          <div
            class="childRow px-0"
            v-for="child in item.items"
            :key="child.id"
          >
            <div
              class="px-4"
              v-show="editMode"
              @click="toggleStatusAlert(child, 'child', item)"
            >
              <v-icon
                @click="editRisk(child, 'child', item)"
                class="pointer mr-2"
                :disabled="!child.enabled"
                >mdi-pencil</v-icon
              >
              <!-- <v-icon
                class="pointer"
                @click="openSuspendConfrim(child, 'child')"
                >mdi-delete</v-icon
              > -->
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
    <!-- === Dialogs === -->
    <!-- 新增/編輯風險 -->
    <material-dialog v-model="editDialog" title="編輯風險" max-width="350">
      <div class="pb-5 ml-3 pt-2">
        <material-input
          v-model="editMeta.displayName"
          dense
          prependTitle="風險名稱"
        ></material-input>
        <material-select
          v-if="editMeta.create"
          v-model="editMeta.category"
          prependTitle="風險層級"
          :items="[
            { text: '風險類型', value: 'main' },
            { text: '風險項目', value: 'child' },
          ]"
          :disabled="!editMeta.create"
        ></material-select>
        <material-input
          v-else
          prependTitle="風險層級"
          noBorder
          :value="categoryToText"
          readonly
        ></material-input>
        <material-autocomplete
          v-show="editMeta.category === 'child'"
          prependTitle="風險類型"
          v-model="editMeta.parentTypeId"
          :items="typeList"
          item-value="id"
          item-text="type"
        ></material-autocomplete>
        <material-input
          v-if="!editMeta.create"
          prependTitle="代碼"
          noBorder
          :value="editMeta.code"
          readonly
        ></material-input>
        <!-- {{ editMeta.code }} -->
      </div>

      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="(editDialog = false), (editMeta = {})"
          >取消</btn-cancel
        >
        <v-btn
          @click="editRiskSubmit"
          depressed
          color="primary"
          :disabled="checkCreate(editMeta)"
          >確定</v-btn
        >
      </template>
    </material-dialog>
    <!-- 停用主要風險/子流程 -->
    <material-dialog
      max-width="500"
      v-model="deleteAlert"
      :title="`停用${editType == 'main' ? '風險類型' : '風險項目'}`"
    >
      <div v-if="editType == 'main'">
        您確定要停用風險類型「<span class="primary--text">{{
          nowItem.type
        }}</span
        >」 嗎？
        <alert-card>風險類型停用後，將不會出現在後續流程中</alert-card>
      </div>
      <div v-else>
        您確定要停用風險項目「<span class="primary--text">{{
          nowItem.name
        }}</span
        >」 嗎？
        <alert-card>風險項目停用後，將不會出現在後續流程中</alert-card>
      </div>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="taggleStatus(nowItem, false)" depressed
          >停用{{ editType == "main" ? "類型" : "項目" }}</v-btn
        >

        <btn-cancel class="mr-2" @click="deleteAlert = false"
          >保留{{ editType == "main" ? "類型" : "項目" }}</btn-cancel
        >
      </template>
    </material-dialog>
    <!-- 儲存成功 -->
    <material-dialog
      v-model="saveSuccessDialog"
      title="儲存成功"
      max-width="400"
    >
      公版版本
      <chip-grey
        >{{ versionData.year }}-{{ versionText(versionData.no) }}</chip-grey
      >
      已成功儲存
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveSuccessDialog = false"
          >我知道了</v-btn
        >
      </template>
    </material-dialog>
    <!-- 簽核軌跡 -->
    <dialog-history
      :dialog="signHistoryDialog"
      :historyData="historyData"
      @close="signHistoryDialog = false"
    ></dialog-history>
    <!-- 送簽 -->
    <to-sign-dialog
      v-if="signDialog == true"
      :dialog="signDialog"
      :version="signVersionData"
      @close="signDialog = false"
      @submit="toSign"
    ></to-sign-dialog>
  </v-container>
</template>
<script>
/**
 TODO:
 1. get types list
 2. riskList CUD （等待 response 確認 ）
*/
import { sync } from "vuex-pathify";
import AlertCard from "./components/AlertCard.vue";
import ToSignDialog from "./components/ToSignDialog.vue";
import { versionMixin, fromSignOffMixin, checkRolesMixin } from "../../helper";
import {
  apiGetRiskTypeById,
  apiGetHistory,
  apiSaveRiskTypeById,
  apiSubmitRiskTypeById,
} from "../../api/risk-type";

export default {
  components: { AlertCard, ToSignDialog },
  mixins: [versionMixin, fromSignOffMixin, checkRolesMixin],
  data() {
    return {
      // --temp--
      versionData: {
        year: "2022",
        No: "01",
        status: 0,
      },
      // ----------------
      riskTypeId: this.$route.params.id,
      headers: [
        {
          text: "風險類型",
          align: "start",
          sortable: false,
          value: "type",
          width: 220,
        },
        { text: "風險項目", value: "item", width: 250 },
        { text: "狀態", value: "status" },
        { text: "操作", value: "actions", width: 50 },
      ],
      riskList: [
        // {
        //   code: "01",
        //   type: "06. 保險風險",
        //   enabled: true,
        //   items: [
        //     {
        //       code: "001",
        //       name: "06.01 核保作業",
        //       enabled: true,
        //     },
        //     {
        //       code: "002",
        //       name: "06.02 批改作業",
        //       enabled: false,
        //     },
        //   ],
        // },
      ],
      expanded: [],
      editMode: false,
      shrinkOrExpand: "allShrink",
      allExpand: false,
      allShrink: false,
      editType: null,
      nowItem: {},
      // === Dialogs ===
      editDialog: false,
      editMeta: {
        displayName: "",
        category: "",
        parentTypeId: "",
        code: "000",
      },
      deleteAlert: false,
      saveSuccessDialog: false,
      signHistoryDialog: false,
      historyData: {},
      updatedAt: null,
      // 送簽參數
      signDialog: false,
      signVersionData: [],
    };
  },
  mounted() {
    // this.versionData = this.$route.query;
    // this.versionData.No = this.versionText(this.$route.query.no);
    // this.versionData.status = Number(this.$route.query.status);
    // this.updatedAt = this.$route.query.updatedAt;
    this.getRisk();
    console.log(this.riskTypeId);
  },
  watch: {
    shrinkOrExpand(val) {
      // console.log({ val });
      if (val == "allShrink") {
        this.expanded = [];
      } else if (val == "allExpand") {
        this.expanded = this.riskList;
      }
    },
    expanded(val) {
      // console.log({ val });
      // console.log(this.riskList);
      // console.log(JSON.stringify(val) == JSON.stringify(this.riskList));
      // console.log(
      //   val.length != 0 || JSON.stringify(val) != JSON.stringify(this.riskList)
      // );
      if (
        val.length == 0 ||
        JSON.stringify(val) == JSON.stringify(this.riskList)
      )
        return;

      this.shrinkOrExpand = "";
    },
  },
  computed: {
    ...sync("global", ["alert"]),
    typeList() {
      let allTypes = this.riskList.map((el) => {
        let obj = {
          id: el.id,
          type: el.type,
          enabled: el.enabled,
        };
        return obj;
      });
      return allTypes.filter((el) => el.enabled);
    },
    categoryToText() {
      if (this.editMeta.category == "main") return "風險類型";
      else if (this.editMeta.category == "child") return "風險項目";
    },
    // isFromSignOff() {
    //   console.log(this.$route.params?.from);
    //   if (this.$route.params?.from == "signOff") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  methods: {
    async getRisk() {
      try {
        let res = await apiGetRiskTypeById(this.riskTypeId);
        console.log({ res });
        if (res.data.content) {
          // test
          // res.data.content.push({
          //   type: "08.test",
          //   enabled: true,
          //   items: [
          //     {
          //       name: "06.01 核保作業1",
          //       enabled: true,
          //     },
          //   ],
          // });
          // ---------
          res.data.content.forEach((type, tIdx) => {
            type.id = tIdx + 1;
            type.items.forEach((item, iIdx) => {
              item.tId = tIdx + 1;
              item.id = iIdx + 1;
            });
          });
          this.riskList = res.data.content;
        }
        if (res.data.riskType) {
          this.versionData = res.data.riskType;
        }
        console.log("riskList", this.riskList);
      } catch (error) {
        console.log(error);
      }
    },
    async saveData() {
      this.editMode = false;
      try {
        let payload = JSON.parse(JSON.stringify(this.riskList));
        payload.forEach((el) => {
          delete el.id;
          el.items.forEach((i) => {
            delete i.id;
            delete i.tId;
          });
        });
        console.log({ payload });
        let res = await apiSaveRiskTypeById(this.riskTypeId, payload);
        console.log({ res });
        this.saveSuccessDialog = true;
      } catch (error) {
        console.log(error); // let url = host_url+'/risk-type/'+this.riskTypeId
        // axios.get(url).then(response => {
        //   console.log(response)
        //   this.riskList = response.data
        // })
        // .catch(error => {
        //   console.log(error)
        // })
      }
    },
    // 編輯風險
    editRisk(item, type, parentItem) {
      console.log({ item });
      this.editDialog = true;
      if (item == null) {
        this.editMeta.create = true;
        return;
      }
      this.nowItem = item;
      this.editMeta = {
        category: type,
        // originCategory: type,
        displayName: type == "main" ? item.type : item.name,
        code: item.code,
      };
      if (type == "main") {
        this.editMeta.hasChildren = this.ifHasChildren(item);
      }
      if (type == "child") {
        // this.editMeta.parentTypeId = parentItem.type;
        this.editMeta.parentTypeId = item.tId;
      }

      console.log(this.nowItem);
      // this.editType = type;
      // TODO:
    },
    editRiskSubmit() {
      console.log("edit submit", this.editMeta);
      let { category, create, displayName, parentTypeId } = this.editMeta;
      // 新增
      if (create) {
        if (category == "main") {
          this.riskList.push({
            type: displayName,
            enabled: true,
            id: this.riskList.length + 1,
            items: [],
            code: this.formatCode(category, this.riskList.length + 1),
          });
          console.log(this.riskList);
        } else if (category == "child") {
          // let p = this.riskList.find((el) => el.type == parentTypeId);
          let p = this.findType(parentTypeId);
          console.log({ p });
          let itemLength = this.riskList.reduce((pre, cur) => {
            return pre + cur.items.length;
          }, 0);
          p.items.push({
            name: displayName,
            enabled: true,
            tId: parentTypeId,
            id: p.items.length + 1,
            code: this.formatCode(category, itemLength + 1),
          });
          if (!this.expanded.find((el) => el.id == parentTypeId)) {
            this.expanded.push(p);
          }
        }
      }
      // 編輯
      // TODO: edit riskList
      else {
        console.log(this.editMeta);
        /* 1. main */
        if (category == "main") {
          let newT = this.findType(this.nowItem.id);
          newT.type = displayName;
          // console.log({ newT });
        } else if (category == "child") {
          console.log(this.nowItem.tId, parentTypeId);
          /* 2. child
              changeName,
              changeParent
        */
          if (this.nowItem.tId == parentTypeId) {
            let newI = this.findItem(this.nowItem.tId, this.nowItem.id);
            console.log({ newI });
            newI.name = displayName;
          } else {
            console.log("hi");
            let p = this.findType(this.nowItem.tId);
            p.items = p.items.filter((el) => el.id != this.nowItem.id);
            let newP = this.findType(parentTypeId);
            // console.log({ p });
            newP.items.push({
              ...this.nowItem,
              name: displayName,
              tId: parentTypeId,
            });
            newP.items.forEach((el, idx) => (el.id = idx));
          }
        }
      }
      this.editDialog = false;
      this.editMeta = {};
    },
    formatCode(category, val) {
      val = val.toString();
      if (category == "main") {
        if (val.length < 2) {
          return `0${val}`;
        }
        return val;
      } else if (category == "child") {
        if (val.length < 2) {
          return `00${val}`;
        } else if (val.length < 3) {
          return `0${val}`;
        } else return val;
      }
    },
    findType(typeId) {
      let t = this.riskList.find((el) => el.id == typeId);
      return t;
    },
    findItem(typeId, itemId) {
      let t = this.findType(typeId);
      let i = t.items.find((el) => el.id == itemId);
      return i;
    },
    // 更改風險類別Alert
    // hasChildrenAlert(val) {
    //   if (val) {
    //     this.alert = {
    //       open: true,
    //       content:
    //         "尚有風險項目於此風險類型中，請將該風險項目移至其他風險類型中",
    //     };
    //   }
    // },
    // 狀態切換
    taggleStatus(item, to) {
      if (!this.editMode) return;
      item.enabled = to;
      if (!to) {
        this.deleteAlert = false;
      }
    },
    toggleStatusAlert(item, category, parentItem) {
      if (item.enabled || !this.editMode) return;
      if (category == "main") {
        this.alert = {
          open: true,
          content: "請先將該類型狀態開啟，再進行編輯動作。",
        };
      } else if (category == "child" && !parentItem.enabled) {
        this.alert = {
          open: true,
          content: "請先將該所屬類型狀態開啟，再進行編輯動作。",
        };
      } else if (category == "child") {
        this.alert = {
          open: true,
          content: "請先將該項目狀態開啟，再進行編輯動作。",
        };
      }
    },
    // 停用Confirm
    openSuspendConfrim(item, type) {
      if (!this.editMode) return;
      this.editType = type;
      let hasActiveChildren = this.ifHasActiveChildren(item);

      if (type == "main" && hasActiveChildren) {
        this.alert = {
          open: true,
          content:
            "尚有風險項目於此風險類型中，請將該風險項目停用或移至其他風險類型中",
        };
        return;
      }
      this.deleteAlert = true;
      this.nowItem = item;
      // TODO:
    },
    // 簽核軌跡
    async signHistory() {
      console.log("here");
      try {
        let res = await apiGetHistory(this.riskTypeId);
        this.signHistoryDialog = true;
        this.historyData = res["data"];
      } catch (error) {
        console.log(error);
      }
      // axios.get(host_url+'/risk-type/'+this.riskTypeId+'/history').then(response => {
      //   console.log(tmp)
      //   this.signHistoryDialog = true
      //   this.historyData = response.data
      // })
      // this.signHistoryDialog = true
    },
    // Checks
    ifHasActiveChildren(item) {
      if (
        item.items &&
        item.items?.length != 0 &&
        item.items.find((el) => el.enabled)
      )
        return true;
      return false;
    },
    ifHasChildren(item) {
      if (item.items && item.items?.length != 0) return true;
      return false;
    },
    checkCreate(editMeta) {
      if (!editMeta.displayName || !editMeta.category) return true;
      if (editMeta.category == "child" && !editMeta.parentTypeId) return true;
      return false;
    },
    async toSign() {
      try {
        let submitData = arguments[0];
        let res = await apiSubmitRiskTypeById(this.riskTypeId, submitData);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    if (this.editMode) {
      this.alert = {
        open: true,
        type: "leaveAlert",
        content: "您尚未儲存，離開資料可能遺失，確定要離開嗎？",
        onOk: () => next(),
        onCancel: () => next(false),
      };
      return next(false);
    }
    return next();
  },
};
</script>
<style scoped lang="scss">
.titleWidth {
  max-width: 480px;
  min-width: 400px;
}
.expandRadio {
  &:deep(.v-label) {
    font-size: 12px;
  }
  &:deep(.v-input--selection-controls__input) {
    width: 20px;
    height: 20px;
    .v-input--selection-controls__ripple {
      width: 20px;
      height: 20px;
      margin: 0;
      top: 0;
      left: 0;
    }
  }
}
.material-table-expand {
  &:deep(.v-data-table-header) {
    background-color: #00a29d;
    th {
      color: #fff !important;
      white-space: nowrap;
      font-size: 14px;
      height: 44px;
      font-weight: 500;
    }
    i {
      color: #fff !important;
    }
  }
  &:deep(.v-data-table__wrapper) {
    td {
      height: 50px;
      font-size: 13px;
    }
  }
  td {
    padding: 0 !important;
  }
  .childRow {
    height: 48px;
    padding: 8px;
    &:not(:last-child) {
      border-bottom: 1px solid rgb(230, 229, 229);
    }
  }
}
</style>
