<template>
  <material-dialog v-model="dialog" title="送簽風險類型與項目" max-width="400">
    <material-select
      v-model="approver"
      prependTitle="請選擇送簽對象"
      label="請選擇"
      :items="approverList"
      item-text="name"
      item-value="id"
    ></material-select>
    <p>
      已選擇 <span class="error--text"> {{ version.length }}</span> 個版本
    </p>
    <chip-grey v-for="(i, idx) of version" :key="idx" class="mr-1"
      >{{ i.year }}-{{ versionText(i.no) }}</chip-grey
    >
    <template #actions>
      <v-spacer></v-spacer>
      <btn-cancel @click="$emit('close')">取消</btn-cancel>
      <v-btn color="primary" depressed @click="submit"> 確定送簽 </v-btn>
    </template>
  </material-dialog>
</template>
<script>
import { versionMixin } from "../../../helper";
import { apiGetUsers } from "../../../api/users";
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    version: {
      type: Array,
      default: [],
    },
  },
  mixins: [versionMixin],
  data() {
    return {
      approver: null,
      approverList: [],
      user: {},
    };
  },
  async mounted() {
    try {
      let searchData = {
        page: 1,
        size: 999,
        roles: "DEPUTY_CHIEF_AUDITOR",
      };
      let res = await apiGetUsers(searchData);
      this.approverList = res.data;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    submit() {
      let user = {};
      user["id"] = this.approver;
      user["role"] = ["DEPUTY_CHIEF_AUDITOR"];
      this.$emit("submit", user);
    },
  },
};
</script>
<style scoped lang="scss"></style>
